// import React, { useEffect, useState, ChangeEvent, useRef } from "react";
import React, { useEffect, useState, ChangeEvent } from "react";
// import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../state/store";
import {
    setLoadingOn,
    setLoadingOff,
} from "../../../state/dashboard/mainSlice";
import { setActiveMenuItem } from "../../../state/dashboard/navigationSlice";
import { setAuthToken } from "../../../service/dashboard/AuthService";
import { setNumberOfBizzClubPartners } from "../../../state/dashboard/userSlice";
import { readConsultantDashboardStatistics } from "../../../service/dashboard/DashboardStatisticsService";
import { ScrollTop } from "primereact/scrolltop";
import {
    MultiStateCheckbox,
    MultiStateCheckboxChangeEvent,
} from "primereact/multistatecheckbox";
import { Accordion, AccordionTab } from "primereact/accordion";
import { Checkbox, CheckboxChangeEvent } from "primereact/checkbox";
import { InputText } from "primereact/inputtext";
import { InputSwitch } from "primereact/inputswitch";
import { Button } from "primereact/button";
// import { Messages } from "primereact/messages";
// import { Ripple } from "primereact/ripple";
import ProductCard from "./ProductCard";
// import clubMemberBannerWide from "../../../asset/opp_clubmember_4x_discount_wide.png";
// import opp50pBannerWide from "../../../asset/opp_webshop_50p_action_wide.png";
// import { isOnlinePartnerInAMonth } from "../../../util/dashboard/OnlinePartnerProgramUtil";
// import ClockCountdown from "../../../component/dashboard/ClockCountdown";
import MessageAlert from "../../../component/MessageAlert";
// import { Message } from "primereact/message";

interface SortMultiStateType {
    value: string;
    icon: string;
}

interface CategoryType {
    key: string;
    name: string;
    description: string;
}

const ProductList: React.FC<any> = () => {
    const dispatch = useDispatch();
    dispatch(setActiveMenuItem("productlist"));

    const userProfile: any = useSelector(
        (state: RootState) => state.user.userProfile
    );

    const numberOfBizzClubPartners: number | null = useSelector(
        (state: RootState) => state.user.numberOfBizzClubPartners
    );

    const productList: [] = useSelector(
        (state: RootState) => state.webshop.productList
    );

    const [productListSortedFiltered, setProductListSortedFiltered] =
        useState<any>([]);

    const [sortValue, setSortValue] = useState<string | null>(null);
    const [searchValue, setSearchValue] = useState<string>("");
    const [itemSetChecked, setItemSetChecked] = useState<boolean>(true);
    const [categories, setCategories] = useState<CategoryType[]>([]);
    const [selectedCategoriesKeys, setSelectedCategoriesKeys] = useState<
        String[]
    >([]);
    const [allSelectedCategoriesKeys, setAllSelectedCategoriesKeys] = useState<
        String[]
    >([]);

    const sortOptions: SortMultiStateType[] = [
        { value: "növekvő ár", icon: "pi pi-sort-amount-down-alt" },
        { value: "csökkenő ár", icon: "pi pi-sort-amount-down" },
    ];

    // const msgs = useRef<any>(null);
    // const [msgsShow, setMsgsShow] = useState<boolean>(false);

    // ezt az infót a Dashboard tölti be, manuális reload esetére ebbe a komponensbe is berakom az állapottartás miatt
    useEffect(() => {
        if (
            userProfile &&
            userProfile.representativeNumber &&
            numberOfBizzClubPartners === null
        ) {
            const request = {
                customerNumber: userProfile.cuNumber,
                representativeNumber: userProfile.representativeNumber,
            };
            readConsultantDashboardStatistics(request).then((response: any) => {
                if (
                    typeof parseInt(response.data.numberOfOwnAllClubMembers) ===
                        "number" &&
                    typeof parseInt(
                        response.data.numberOfOwnAllPendingClubMembers
                    ) === "number"
                ) {
                    dispatch(
                        setNumberOfBizzClubPartners(
                            parseInt(response.data.numberOfOwnAllClubMembers) +
                                parseInt(
                                    response.data
                                        .numberOfOwnAllPendingClubMembers
                                )
                        )
                    );
                    // TESZT
                    // dispatch(setNumberOfBizzClubPartners(0));
                } else {
                    dispatch(setNumberOfBizzClubPartners(0));
                }
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userProfile, numberOfBizzClubPartners]);

    // useMountEffect(() => {
    // useEffect(() => {
    //     if (
    //         msgs.current &&
    //         msgs.current.show &&
    //         msgsShow === false &&
    //         userProfile.zepterRank &&
    //         (userProfile.zepterRank === "CLUBMEMBER" ||
    //             userProfile.zepterRank === "REPRESENTATIVE") &&
    //         numberOfBizzClubPartners !== null &&
    //         numberOfBizzClubPartners < 50
    //     ) {
    //         msgs.current?.clear();
    //         setMsgsShow(true);
    // let message = (
    //     <>
    //         <span className="text-xl">Tudtad, hogy már</span>
    //         <ul>
    //             {numberOfBizzClubPartners < 1 ? (
    //                 <li className="text-indigo-500">
    //                     {1 - numberOfBizzClubPartners} új ZepterClub tag
    //                     ajánlásával{" "}
    //                     <b>
    //                         TRIPLÁZÓDIK a kedvezményed, ami akár 15% is
    //                         lehet? 🤩
    //                     </b>
    //                     <br />
    //                     <span className="text-teal-500">
    //                         <i>
    //                             Ezt a lépést egy egyszerű Online Partner
    //                             regisztrációval ki lehet váltani és
    //                             azonnal NÉGYSZERESÉRE nő a
    //                             kedvezményszinted.
    //                         </i>
    //                     </span>
    //                 </li>
    //             ) : null}
    //             {numberOfBizzClubPartners < 2 ? (
    //                 <li className="text-indigo-500">
    //                     {2 - numberOfBizzClubPartners} új ZepterClub tag
    //                     ajánlásával{" "}
    //                     <b>
    //                         NÉGYSZERESÉRE nő a kedvezményed, ami akár
    //                         20% is lehet? 🥳
    //                     </b>
    //                     <br />
    //                     <span className="text-teal-500">
    //                         <i>
    //                             Ezt a lépést egy egyszerű Online Partner
    //                             regisztrációval is ki lehet váltani.
    //                         </i>
    //                     </span>
    //                 </li>
    //             ) : null}
    //             {numberOfBizzClubPartners < 3 ? (
    //                 <li className="text-indigo-500">
    //                     {3 - numberOfBizzClubPartners} új ZepterClub tag
    //                     ajánlásával{" "}
    //                     <b>
    //                         ÖTSZÖRÖSÉRE nő a kedvezményed, ami akár 25%
    //                         is lehet? 📈
    //                     </b>
    //                 </li>
    //             ) : null}
    //             {numberOfBizzClubPartners < 4 ? (
    //                 <li className="text-indigo-500">
    //                     {4 - numberOfBizzClubPartners} új ZepterClub tag
    //                     ajánlásával{" "}
    //                     <b>
    //                         HATSZOROSÁRA nő a kedvezményed, ami akár 30%
    //                         is lehet? 😱
    //                     </b>
    //                     <br />
    //                     {userProfile.zepterRank &&
    //                     userProfile.zepterRank === "REPRESENTATIVE" ? (
    //                         <span className="text-teal-500">
    //                             <i>
    //                                 Ezt a kedvezményszintet JUINOR
    //                                 PARNTER rang megvalósításával is el
    //                                 lehet érni.
    //                             </i>
    //                         </span>
    //                     ) : null}
    //                 </li>
    //             ) : null}
    //             {/* {numberOfBizzClubPartners < 40 ? (
    //                 <li className="text-indigo-500">
    //                     {40 - numberOfBizzClubPartners} új ZepterClub
    //                     tag ajánlásával{" "}
    //                     <b>
    //                         HÉTSZERESÉRE nő a kedvezményed, ami akár 35%
    //                         is lehet? 🚀
    //                     </b>
    //                 </li>
    //             ) : null} */}
    //             {/* {numberOfBizzClubPartners < 50 ? (
    //                 <li className="text-indigo-500">
    //                     {50 - numberOfBizzClubPartners} új ZepterClub
    //                     tag ajánlásával{" "}
    //                     <b>
    //                         NYOLCSZOROSÁRA nő a kedvezményed, ami akár
    //                         35% is lehet? 💎
    //                     </b>
    //                     <br />
    //                     {userProfile.zepterRank &&
    //                     userProfile.zepterRank === "REPRESENTATIVE" ? (
    //                         <>
    //                             <span className="text-teal-500">
    //                                 <i>
    //                                     Ezt a kedvezményszintet TEAM
    //                                     PARNTER feletti rang
    //                                     megvalósításával is el lehet
    //                                     érni.
    //                                 </i>
    //                             </span>
    //                             <a
    //                                 className="mt-5 p-ripple flex align-items-center cursor-pointer p-3 text-700 border-2 border-300 hover:border-600 transition-duration-150 transition-colors"
    //                                 style={{
    //                                     borderRadius: "12px",
    //                                     textDecoration: "none",
    //                                 }}
    //                                 href="https://zepterclub.hu/upload/pdf/Marketing_Terv_2024-01_HU_20240131.pdf"
    //                                 target="_blank"
    //                                 rel="noreferrer"
    //                             >
    //                                 <i className="pi pi-euro mr-2 text-indigo-500"></i>
    //                                 <span className="font-bold text-indigo-500">
    //                                     Marketing Terv 2024.01.31-től
    //                                     2024.03.26-ig
    //                                 </span>
    //                                 <Ripple />
    //                             </a>
    //                         </>
    //                     ) : null}
    //                 </li>
    //             ) : null} */}
    //         </ul>
    //         {userProfile && userProfile.zepterRank === "CLUBMEMBER" ? (
    //             <Button
    //                 label="Regisztrálok Online Partnernek"
    //                 icon="pi pi-user-plus"
    //                 className="p-ripple p-component cursor-pointer mb-2 p-3 border-round-3xl surface-500 bg-teal-500 hover:bg-teal-700 border-none text-0 w-full transition-all transition-duration-300 text-center"
    //                 style={{
    //                     border: "none",
    //                     borderRadius: "2rem",
    //                     top: "0.5rem",
    //                     bottom: "0.5rem",
    //                 }}
    //                 onClick={() => {
    //                     navigate("../consultantregistration");
    //                     dispatch(
    //                         setActiveMenuItem("consultantregistration")
    //                     );
    //                 }}
    //             />
    //         ) : null}
    //         <Button
    //             label="Irány az ajánlói linkem"
    //             icon="pi pi-share-alt"
    //             className="p-ripple p-component cursor-pointer mb-2 p-3 border-round-3xl surface-500 bg-blue-500 hover:bg-blue-700 border-none text-0 w-full transition-all transition-duration-300 text-center"
    //             style={{
    //                 border: "none",
    //                 borderRadius: "2rem",
    //                 top: "0.5rem",
    //                 bottom: "0.5rem",
    //             }}
    //             onClick={() => {
    //                 navigate("../recommenderlink");
    //                 dispatch(setActiveMenuItem("recommenderlink"));
    //             }}
    //         />
    //     </>
    // );
    // msgs.current.show([
    //     {
    //         sticky: true,
    //         severity: "info",
    //         summary: "Info",
    //         content: (
    //             <>
    //                 <i
    //                     className="text-indigo-500 pi pi-question-circle"
    //                     style={{ fontSize: "2rem" }}
    //                 />
    //                 <div className="ml-2">{message}</div>
    //             </>
    //         ),
    //     },
    // ]);
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [numberOfBizzClubPartners, msgs, msgsShow, userProfile.zepterRank]);

    // Kezdő lépések, kategória beállítás, mindet kijelőle, ABC sorrendbe rendezi
    useEffect(() => {
        if (categories.length === 0) {
            const categoriesSet = new Set();
            const categoriesTmp: CategoryType[] = [];
            const selectedCategoriesKeysTmp: String[] = [];
            if (productList && productListSortedFiltered.length === 0) {
                productList.forEach((product: any) => {
                    if (product.productCategory) {
                        categoriesSet.add(product.productCategory.id);
                    }
                });
                productList.forEach((product: any) => {
                    if (product.productCategory) {
                        // QunoMed és MyIon kategóriát kiveszi és aminek nincs rrp-je
                        if (
                            categoriesSet.has(product.productCategory.id) &&
                            product.productCategory.name !== "QuanoMed" &&
                            product.productCategory.name !== "MyIon" &&
                            product.productPriceGroup &&
                            product.productPriceGroup.rrp
                        ) {
                            categoriesTmp.push({
                                key: product.productCategory.id,
                                name: product.productCategory.name,
                                description:
                                    product.productCategory.description,
                            });
                            selectedCategoriesKeysTmp.push(
                                product.productCategory.id
                            );

                            categoriesSet.delete(product.productCategory.id);
                        }
                    }
                });
                categoriesTmp.sort((a: any, b: any) =>
                    a.name.localeCompare(b.name)
                );
                // Kiveszi, azt a terméket aminek nincs rrp-je, rendezés miatt fontos
                setProductListSortedFiltered(
                    productList.filter(
                        (product: any) =>
                            product.productPriceGroup &&
                            product.productPriceGroup.rrp
                    )
                );
                setCategories(categoriesTmp);
                setSelectedCategoriesKeys(selectedCategoriesKeysTmp);
                setAllSelectedCategoriesKeys(selectedCategoriesKeysTmp);
            }
        }
    }, [categories.length, productList, productListSortedFiltered.length]);

    // let navigate = useNavigate();
    setAuthToken();

    // Ha a productList betöltött akkor, a loadingot kikapcsolja
    useEffect(() => {
        if (productList.length === 0) {
            dispatch(
                setLoadingOn({
                    blockPage: true,
                    message: "Termékek betöltése folyamatban.",
                })
            );
        } else {
            setLoadingOff();
        }
    }, [dispatch, productList.length]);

    // Kategória változások esetén
    const onCategoryChange = (e: CheckboxChangeEvent) => {
        let _selectedCategories = [...selectedCategoriesKeys];
        if (e.checked) _selectedCategories.push(e.value.key);
        else
            _selectedCategories = _selectedCategories.filter(
                (category) => category !== e.value.key
            );

        setSelectedCategoriesKeys(_selectedCategories);
    };

    // Rendezés beállítása
    useEffect(() => {
        if (productList && productList.length > 1) {
            let productListSorted: any[] = [];

            // Keresés
            if (searchValue !== "") {
                productListSorted = productList.filter((product: any) => {
                    if (
                        product.name &&
                        product.itemNumber &&
                        product.shortDescription
                    ) {
                        return (
                            product.productPriceGroup &&
                            product.productPriceGroup.rrp &&
                            (product.name
                                .toLowerCase()
                                .includes(searchValue.toLowerCase()) ||
                                product.itemNumber
                                    .toLowerCase()
                                    .includes(searchValue.toLowerCase()) ||
                                product.shortDescription
                                    .toLowerCase()
                                    .includes(searchValue.toLowerCase()))
                        );
                    } else {
                        return false;
                    }
                });
            } else {
                productListSorted = productList.filter(
                    (product: any) =>
                        product.productPriceGroup &&
                        product.productPriceGroup.rrp
                );
            }

            // Rendezés
            if (sortValue === "növekvő ár" && productListSorted.length > 1) {
                productListSorted.sort((productA: any, productB: any) => {
                    if (
                        productA.productPriceGroup &&
                        productB.productPriceGroup &&
                        productA.productPriceGroup.pl4 &&
                        productB.productPriceGroup.pl4
                    ) {
                        return (
                            Number(productA.productPriceGroup.pl4) -
                            Number(productB.productPriceGroup.pl4)
                        );
                    } else {
                        return 0;
                    }
                });
            } else if (
                sortValue === "csökkenő ár" &&
                productListSorted.length > 1
            ) {
                productListSorted.sort((productA: any, productB: any) => {
                    if (
                        productA.productPriceGroup &&
                        productB.productPriceGroup &&
                        productA.productPriceGroup.pl4 &&
                        productB.productPriceGroup.pl4
                    )
                        return (
                            Number(productB.productPriceGroup.pl4) -
                            Number(productA.productPriceGroup.pl4)
                        );
                    else return 0;
                });
            }

            setProductListSortedFiltered(productListSorted);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchValue, sortValue]);
    // const bizzClubAction202404 = () => (
    //     <div className="text-pink-600">
    //         BizzClub MyBasics akció [2024.04.22 - 2024.05.05.]
    //         <br />
    //         <br />
    //         <ul>
    //             <li className="text-pink-600">
    //                 Klubtagoknak <strong>40%-50%</strong> kedvezmény
    //             </li>
    //             <li className="text-pink-600">
    //                 Online Partnereknek és Tanácsadóknak{" "}
    //                 <strong>48%-60%</strong> kedvezmény
    //             </li>
    //         </ul>
    //         <br />
    //         Az akcióban a SkinOptim termék nem vesz részt.
    //     </div>
    // );
    return (
        <div className="max-w-max">
            <div className="grid max-w-full mb-0 md:mb-3">
                <div className="col-12">
                    <MessageAlert />
                </div>
            </div>
            <div className="grid max-w-full mb-0 md:mb-3">
                <div className="text-center mt-1 col-3 md:col-2 xl:col-1">
                    <MultiStateCheckbox
                        value={sortValue}
                        onChange={(e: MultiStateCheckboxChangeEvent) =>
                            setSortValue(e.value)
                        }
                        options={sortOptions}
                        optionValue="value"
                    />
                    <div className="text-sm mt-1 md:text-lg">
                        {sortValue || "nincs rendezés"}
                    </div>
                </div>
                <div className="justify-content-center text-center col-9 md:col-5 xl:col-4">
                    <span className="justify-content-center p-input-icon-left">
                        <i className="pi pi-search" />
                        <InputText
                            className="p-inputtext-lg w-full"
                            placeholder="Keresés"
                            value={searchValue}
                            onChange={(e: ChangeEvent<HTMLInputElement>) =>
                                setSearchValue(e.target.value)
                            }
                        />
                    </span>
                </div>
                <div className="justify-content-center text-center align-content-center col-12 md:col-5 xl:col-7">
                    <Accordion>
                        <AccordionTab
                            className="flex-wrap align-content-center"
                            header="Kategória szűrő"
                        >
                            <div className="flex flex-wrap gap-3 align-content-center mb-2">
                                <div className="flex align-items-center justify-content-center">
                                    <InputSwitch
                                        className="mt-1"
                                        checked={itemSetChecked}
                                        onChange={(e) => {
                                            if (
                                                e &&
                                                e.value !== undefined &&
                                                e.value !== null
                                            ) {
                                                setItemSetChecked(e.value);
                                            }
                                        }}
                                    />
                                    <span className="flex ml-2">
                                        Szettek megjelenítése
                                    </span>
                                </div>
                                <Button
                                    className="flex"
                                    label={
                                        selectedCategoriesKeys.length === 0
                                            ? "Mind kijelőlése"
                                            : "Kijelőlések törlése"
                                    }
                                    icon={
                                        selectedCategoriesKeys.length === 0
                                            ? "pi pi-check-square"
                                            : "pi pi-stop"
                                    }
                                    size="small"
                                    severity={
                                        selectedCategoriesKeys.length === 0
                                            ? "success"
                                            : "help"
                                    }
                                    rounded
                                    onClick={() => {
                                        // console.log(allSelectedCategoriesKeys);
                                        selectedCategoriesKeys.length === 0
                                            ? setSelectedCategoriesKeys([
                                                  ...allSelectedCategoriesKeys,
                                              ])
                                            : setSelectedCategoriesKeys([]);
                                    }}
                                />
                            </div>
                            <div className="flex flex-wrap gap-4 mt-4">
                                {categories.length > 0
                                    ? categories.map(
                                          (category: CategoryType) => {
                                              return (
                                                  <div
                                                      key={category.key}
                                                      className="flex align-items-center"
                                                  >
                                                      <Checkbox
                                                          inputId={category.key}
                                                          name="category"
                                                          value={category}
                                                          onChange={
                                                              onCategoryChange
                                                          }
                                                          checked={selectedCategoriesKeys.some(
                                                              (item) =>
                                                                  item ===
                                                                  category.key
                                                          )}
                                                      />
                                                      <label
                                                          htmlFor={category.key}
                                                          className="ml-2 text-left"
                                                      >
                                                          {category.name}
                                                      </label>
                                                  </div>
                                              );
                                          }
                                      )
                                    : null}
                            </div>
                        </AccordionTab>
                    </Accordion>
                </div>
            </div>
            {/* <div className="grid mt-1 ml-1 mr-1 justify-content-center md:-mt-3"> */}
            {/* 2025.03.08-án kikapcsolva */}
            {/* {userProfile && userProfile.zepterRank === "CLUBMEMBER" ? (
                    <div className="flex align-items-center justify-content-center">
                        <img
                            className="max-w-full mb-2 border-round-xl cursor-pointer fadeinleft animation-duration-500"
                            src={clubMemberBannerWide}
                            alt="Online Partner Program előnyök"
                            onClick={() => {
                                navigate("/main/consultantregistration");
                            }}
                        />
                    </div>
                ) : null} */}
            {/* MT szerint PL ajánlói kedvezmény felhívás */}
            {/* {numberOfBizzClubPartners !== null &&
                numberOfBizzClubPartners < 50 &&
                userProfile.zepterRank &&
                (userProfile.zepterRank === "CLUBMEMBER" ||
                    userProfile.zepterRank === "REPRESENTATIVE") ? (
                    <div className="flex-wrap align-items-center justify-content-center gap-3 max-w-full mb-2 fadeinleft animation-duration-500">
                        <Messages ref={msgs} />
                    </div>
                ) : null} */}
            {/* TODO 2023.06.30-án kikapcsolni */}
            {/* {userProfile &&
                userProfile.zepterRank === "REPRESENTATIVE" &&
                userProfile.representativeRegistrationDate &&
                isOnlinePartnerInAMonth(
                    userProfile.representativeRegistrationDate
                ).discount ? (
                    <div className="flex flex-column align-items-center justify-content-center">
                        <img
                            className="max-w-full mb-2 border-round-xl cursor-pointer fadeinleft animation-duration-500"
                            src={opp50pBannerWide}
                            alt="Új Online Partner első havi kedvezmény"
                        />
                        <div className="mt-2 md:-mt-8 md:ml-8">
                            <ClockCountdown
                                endTime={
                                    isOnlinePartnerInAMonth(
                                        userProfile.representativeRegistrationDate
                                    ).endDate
                                }
                            />
                        </div>
                    </div>
                ) : null} */}
            {/* </div> */}
            {/* <div className="flex flex-column align-items-center justify-content-center max-w-full mt-3 mb-3">
                <Message
                    className="max-w-full border-pink-500 border-2 p-4"
                    text={bizzClubAction202404()}
                    severity="info"
                />
            </div> */}

            <div className="grid mt-1 justify-content-center">
                {productListSortedFiltered &&
                productListSortedFiltered.length > 0 ? (
                    productListSortedFiltered.map((product: any) => {
                        if (
                            product.id &&
                            product.itemNumber &&
                            product.productCategory &&
                            product.productCategory.id &&
                            product.itemSet !== undefined &&
                            product.itemSet !== "undefined"
                        ) {
                            //Pozitura és MyIon nélkül, azok a kategóriák, amelyek nincsenek kijelőlve
                            return product.itemNumber !== "ION-03" &&
                                product.itemNumber !== "DP-P" &&
                                selectedCategoriesKeys.includes(
                                    product.productCategory.id
                                ) &&
                                ((product.itemSet && itemSetChecked) ||
                                    !product.itemSet) ? (
                                <div
                                    key={
                                        "product-card-" + product.id.toString()
                                    }
                                >
                                    <ProductCard id={product.id} />
                                </div>
                            ) : null;
                        } else {
                            return null;
                        }
                    })
                ) : (
                    <div className="text-center text-lg">
                        Nincs termék találat
                    </div>
                )}
            </div>
            <ScrollTop
                target="window"
                threshold={300}
                className="w-3rem h-3rem border-round bg-purple-500"
                icon="pi pi-arrow-up text-base"
            />
        </div>
    );
};

export default ProductList;
