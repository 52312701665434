import React, { useLayoutEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import { useState, useEffect } from "react";
import { setLocalHU } from "../config/localhu";
import { getCurrentUser } from "../service/dashboard/AuthService";
import WelcomeLoginRoute from "../route/WelcomeLoginRoute";
import ProtectedRoute from "../route/ProtectedRoute";
import ValidRecommenderRoute from "../route/ValidRecommenderRoute";
import { Menubar } from "primereact/menubar";
import { useSearchParams } from "react-router-dom";
import {
    checkRecommenderLink,
    readInvited,
} from "../service/home/ClubMemberRegistrationService";
import GetPassword from "./home/GetPassword";
import ChangePassword from "./home/ChangePassword";
import Home from "./home/Home";
import Footer from "./home/Footer";
import Login from "./home/Login";
import Registration from "./home/ClubMemberRegistration";
import NotFound from "./home/NotFound";
import Main from "./dashboard/Main";
import "../css/App.css";
import GeneralTermsClubMember220615 from "./regulation/GeneralTermsClubMember220615";
import GeneralTermsClubMember221012 from "./regulation/GeneralTermsClubMember221012";
import GeneralTermsClubMember230215 from "./regulation/GeneralTermsClubMember230215";
import GeneralTermsClubMember230301 from "./regulation/GeneralTermsClubMember230301";
import GeneralTermsClubMember230329 from "./regulation/GeneralTermsClubMember230329";
import GeneralTermsClubMember230526 from "./regulation/GeneralTermsClubMember230526";
import GeneralTermsMyBasicsNYJ2022 from "./regulation/GeneralTermsMyBasicsNYJ2022";
import zepterCl100LogoLightBlue from "../asset/zepter_bizzclub_logo_black.png";
import GeneralTermsCMOPPNYJ2023Q1 from "./regulation/GeneralTermsCMOPPNYJ2023Q1";
import GeneralTermsCMOPPNYJ2023Q2 from "./regulation/GeneralTermsCMOPPNYJ2023Q2";
import GeneralTermsCMOPPNYJ2023Q3 from "./regulation/GeneralTermsCMOPPNYJ2023Q3";
import GeneralTermsCMNYJ2023Q4 from "./regulation/GeneralTermsCMNYJ2023Q4";
import GeneralTermsCMNYJ2024Q1 from "./regulation/GeneralTermsCMNYJ2024Q1";
import GeneralTermsCMNYJ2024Q2 from "./regulation/GeneralTermsCMNYJ2024Q2";
import GeneralTermsCMNYJ2024Q3 from "./regulation/GeneralTermsCMNYJ2024Q3";
import GeneralTermsCMNYJ2024Q4 from "./regulation/GeneralTermsCMNYJ2024Q4";
import GeneralTermsCMNYJ2025Q1 from "./regulation/GeneralTermsCMNYJ2025Q1";

function getSessionStorage(key: string) {
    const stored = sessionStorage.getItem(key);
    return stored;
}

type ScrollToTopWrapperProps = {
    children: React.ReactNode;
};

const ScrollToTopWrapper = (props: ScrollToTopWrapperProps) => {
    const location = useLocation();
    useLayoutEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }, [location.pathname]);
    return <div>{props.children}</div>;
};

const App = () => {
    // console.log('App is loading');
    setLocalHU();
    let navigate = useNavigate();
    let [searchParams] = useSearchParams();
    const [validRecommenderLink, setValidRecommenderLink] =
        useState<boolean>(false);
    const [validInvitationLink, setValidInvitationLink] =
        useState<boolean>(false);

    const user = getCurrentUser();
    // if (user !== null) {
    //     navigate("/registration");
    // }

    let clubMemberId: string | null = searchParams.get("cm");
    let clubConsultantId: string | null = searchParams.get("cc");
    let invitationId: string | null = searchParams.get("invitation");
    let root: boolean = true;

    if (clubMemberId !== null) {
        sessionStorage.setItem("cm", clubMemberId);
    } else {
        clubMemberId = getSessionStorage("cm");
    }

    if (clubConsultantId !== null) {
        sessionStorage.setItem("cc", clubConsultantId);
    } else {
        clubConsultantId = getSessionStorage("cc");
    }

    if (invitationId !== null) {
        sessionStorage.setItem("invitation", invitationId);
    } else {
        invitationId = getSessionStorage("invitation");
    }

    // if (clubConsultantId === null && clubMemberId === null) {
    if (clubMemberId !== null || invitationId !== null) {
        root = false;
    }

    const itemsLeft1 = [
        {
            label: "Regisztráció",
            icon: "pi pi-fw pi-plus-circle",
            command: () => {
                navigate("/registration");
            },
        },
        {
            label: "Bejelentkezés",
            icon: "pi pi-fw pi-user",
            command: () => {
                navigate("/login");
            },
        },
        {
            label: "Jelszó igénylés",
            icon: "pi pi-fw pi-lock-open",
            command: () => {
                navigate("/getpassword");
            },
        },
        {
            label: "ZepterClub szabályzat",
            icon: "pi pi-fw pi-book",
            command: () => {
                window.open(
                    "https://www.zepter.hu/Rules/Regulation-ZepterClub",
                    "_blank"
                );
            },
        },
        // {
        //     label: "🍀 Nyereményjáték",
        //     icon: "",
        //     command: () => {
        //         navigate("/2025-q1-nyeremenyjatek-szabalyzat");
        //     },
        // },
    ];

    const itemsLeft2 = [
        {
            label: "Bejelentkezés",
            icon: "pi pi-fw pi-user",
            command: () => {
                navigate("/login");
            },
        },
        {
            label: "Jelszó igénylés",
            icon: "pi pi-fw pi-lock-open",
            command: () => {
                navigate("/getpassword");
            },
        },
        {
            label: "ZepterClub szabályzat",
            icon: "pi pi-fw pi-book",
            command: () => {
                window.open(
                    "https://www.zepter.hu/Rules/Regulation-ZepterClub",
                    "_blank"
                );
            },
        },
        // {
        //     label: "🍀 Nyereményjáték",
        //     icon: "",
        //     command: () => {
        //         navigate("/2025-q1-nyeremenyjatek-szabalyzat");
        //     },
        // },
    ];

    useEffect(() => {
        // console.log("App useEffect is called");
        if (!root) {
            if (invitationId !== null) {
                readInvited(invitationId).then(
                    (response) => {
                        setValidInvitationLink(true);
                    },
                    (error) => {
                        // console.log(error);
                        setValidInvitationLink(false);
                    }
                );
            }
            if (clubMemberId !== null) {
                checkRecommenderLink(clubMemberId, clubConsultantId).then(
                    (response) => {
                        setValidRecommenderLink(true);
                    },
                    (error) => {
                        // console.log(error.response.status);
                        setValidRecommenderLink(false);
                    }
                );
            }
        }
    }, [invitationId, clubMemberId, clubConsultantId, root]);

    return (
        <div>
            <div>
                {user ? null : (
                    <div
                        className="surface-overlay py-3 px-6 shadow-2 align-items-center flex justify-content-between relative lg:static"
                        style={{ minHeight: "80px" }}
                    >
                        <a href="/">
                            <img
                                alt="logo"
                                src={zepterCl100LogoLightBlue}
                                height="40"
                                className="mr-2"
                            ></img>
                        </a>
                        {!root &&
                        (validRecommenderLink || validInvitationLink) ? (
                            <Menubar model={itemsLeft1} />
                        ) : (
                            <Menubar model={itemsLeft2} />
                        )}
                    </div>
                )}
            </div>
            <div className="mb-3">
                <div>
                    {user ? null : (
                        <div className="px-4 py-3 md:px-6 md:py-5 lg:px-8"></div>
                    )}
                </div>
                <ScrollToTopWrapper>
                    <Routes>
                        <Route element={<WelcomeLoginRoute />}>
                            <Route path={"/"} element={<Home />} />
                        </Route>
                        <Route
                            path={"/cl100generaltermsandconditions220615"}
                            element={<GeneralTermsClubMember220615 />}
                        />
                        <Route
                            path={"/cl100generaltermsandconditions221012"}
                            element={<GeneralTermsClubMember221012 />}
                        />
                        <Route
                            path={"/cl100generaltermsandconditions230215"}
                            element={<GeneralTermsClubMember230215 />}
                        />
                        <Route
                            path={"/cl100generaltermsandconditions230301"}
                            element={<GeneralTermsClubMember230301 />}
                        />
                        <Route
                            path={"/cl100generaltermsandconditions230329"}
                            element={<GeneralTermsClubMember230329 />}
                        />
                        <Route
                            path={"/cl100generaltermsandconditions230526"}
                            element={<GeneralTermsClubMember230526 />}
                        />
                        <Route
                            path={"/2022_mybasic_nyeremenyjatek_szabalyzat"}
                            element={<GeneralTermsMyBasicsNYJ2022 />}
                        />
                        <Route
                            path={"/2022_mybasics_nyeremenyjatek_szabalyzat"}
                            element={<GeneralTermsMyBasicsNYJ2022 />}
                        />
                        <Route
                            path={"/2023-q1-nyeremenyjatek-szabalyzat"}
                            element={<GeneralTermsCMOPPNYJ2023Q1 />}
                        />
                        <Route
                            path={"/2023-q2-nyeremenyjatek-szabalyzat"}
                            element={<GeneralTermsCMOPPNYJ2023Q2 />}
                        />
                        <Route
                            path={"/2023-q3-nyeremenyjatek-szabalyzat"}
                            element={<GeneralTermsCMOPPNYJ2023Q3 />}
                        />
                        <Route
                            path={"/2023-q4-nyeremenyjatek-szabalyzat"}
                            element={<GeneralTermsCMNYJ2023Q4 />}
                        />
                        <Route
                            path={"/2024-q1-nyeremenyjatek-szabalyzat"}
                            element={<GeneralTermsCMNYJ2024Q1 />}
                        />
                        <Route
                            path={"/2024-q2-nyeremenyjatek-szabalyzat"}
                            element={<GeneralTermsCMNYJ2024Q2 />}
                        />
                        <Route
                            path={"/2024-q3-nyeremenyjatek-szabalyzat"}
                            element={<GeneralTermsCMNYJ2024Q3 />}
                        />
                        <Route
                            path={"/2024-q4-nyeremenyjatek-szabalyzat"}
                            element={<GeneralTermsCMNYJ2024Q4 />}
                        />
                        <Route
                            path={"/2025-q1-nyeremenyjatek-szabalyzat"}
                            element={<GeneralTermsCMNYJ2025Q1 />}
                        />
                        <Route
                            path={"/getpassword"}
                            element={<GetPassword />}
                        />
                        <Route
                            path={"/changepassword"}
                            element={<ChangePassword />}
                        />
                        <Route path={"/notfound"} element={<NotFound />} />
                        <Route element={<ValidRecommenderRoute />}>
                            <Route
                                path={"/registration"}
                                element={<Registration />}
                            />
                        </Route>
                        <Route path={"/login"} element={<Login />} />
                        <Route element={<ProtectedRoute />}>
                            <Route path={"/main/*"} element={<Main />} />
                        </Route>
                        <Route path="*" element={<NotFound />} />
                    </Routes>
                </ScrollToTopWrapper>
            </div>
            <Footer />
        </div>
    );
};

export default App;
